import React from "react";
import { Redirect } from "react-router-dom";
import bridge from "@vkontakte/vk-bridge";

class Intro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  toStart = value => {
    if (value) bridge.send("VKWebAppAllowNotifications", {});
    this.props.notNewbie();
    this.setState({ redirect: true });
    setTimeout(() => {
      bridge.send("VKWebAppJoinGroup", { group_id: 181889580 });
    }, 20000);
  };

  render() {
    if (this.state.redirect) return <Redirect to="/" />

    return (
      <div className="intro">
        <div style={{ width: "100%" }}>
          <div className="icon"> </div>
          <p className="description">
            Добро пожаловать
            <br />
            на борт станции!
          </p>
          <p className="let-subscribe">
            Подпишись на уведомления, чтоб не пропустить космические новости
          </p>
          <div className="subscribe" onClick={() => this.toStart(true)}>
            Ok
          </div>
          <div className="continue" onClick={() => this.toStart(false)}>
            Продолжить
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;