import React from "react";
import { Redirect } from "react-router-dom";
import bridge from "@vkontakte/vk-bridge";
import { FixedLayout, PromoBanner } from "@vkontakte/vkui";

const gif = new Image();
gif.src = "/iss.gif";

class Station extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noAd: false,
      backend: this.props.backend,
      promoBannerProps: null,
      first_link: this.props.first_link,
      second_link: this.props.second_link,
      player: 1,
      toWallClass: this.props.toWallClass,
      toHistoryClass: this.props.toHistoryClass,
      toWall: this.props.toWall,
      toHistory: this.props.toHistory,
      legends: [
        {
          id: 1,
          photo: "legends/gagarin.jpg",
          name: "Юрий",
          last_name: "Гагарин",
          record1: "первый",
          record2: "в космосе",
          record3: "",
        },
        {
          id: 2,
          photo: "legends/armstrong.jpg",
          name: "Нил",
          last_name: "Армстронг",
          record1: "первый",
          record2: "на Луне",
          record3: "",
        },
        {
          id: 3,
          photo: "legends/leonov.jpg",
          name: "Алексей",
          last_name: "Леонов",
          record1: "первый",
          record2: "в открытом",
          record3: "космосе",
        },
        {
          id: 4,
          photo: "legends/tereshkova.jpg",
          name: "Валентина",
          last_name: "Терешкова",
          record1: "первая",
          record2: "женщина",
          record3: "космонавт",
        },
        {
          id: 5,
          photo: "legends/glen.jpg",
          name: "Джон",
          last_name: "Гленн",
          record1: "самый",
          record2: "возрастной",
          record3: "астронавт",
        },
        {
          id: 6,
          photo: "legends/savitskaya.jpg",
          name: "Светлана",
          last_name: "Савицкая",
          record1: "первая женщина",
          record2: "в открытом",
          record3: "космосе",
        },
        {
          id: 7,
          photo: "legends/polyakov.jpg",
          name: "Валерий",
          last_name: "Поляков",
          record1: "самый",
          record2: "длительный",
          record3: "полет",
        },
        {
          id: 8,
          photo: "legends/padalka.jpg",
          name: "Геннадий",
          last_name: "Падалка",
          record1: "рекорд",
          record2: "пребывания",
          record3: "в космосе",
        },
        {
          id: 9,
          photo: "legends/solovev.jpg",
          name: "Анатолий",
          last_name: "Соловьев",
          record1: "рекорд",
          record2: "выхода в",
          record3: "открытый космос",
        },
        {
          id: 10,
          photo: "legends/tito.jpg",
          name: "Деннис",
          last_name: "Тито",
          record1: "первый",
          record2: "космический",
          record3: "турист",
        },
      ],
    };
  }

  componentDidMount() {
    bridge.send('VKWebAppGetAds', {}).then((promoBannerProps) => {
      console.log('VKWebAppGetAds then', promoBannerProps);
      this.setState({ promoBannerProps: promoBannerProps }); 
    }).catch((error) => {
      console.log('VKWebAppGetAds error', error);
      this.setState({ noAd: true });
    });

    setTimeout(() => {
      bridge.send('VKWebAppCheckNativeAds', { ad_format: 'interstitial' })
      .then((result) => {
        console.log('VKWebAppCheckNativeAds then', result);
      }).catch((error) => {
        console.log('VKWebAppCheckNativeAds error', error);
      });

      bridge.send('VKWebAppShowNativeAds', { ad_format: 'interstitial' })
      .then((result) => {
        console.log('VKWebAppShowNativeAds then', result);
      }).catch((error) => {
        console.log('VKWebAppShowNativeAds error', error);
        this.setState({ noAd: true });
      });
    }, 20000);

    bridge.subscribe(e => {
      const self = this;
      switch (e.detail.type) {
        case "VKWebAppShowWallPostBoxResult":
          bridge.send("VKWebAppSetViewSettings", {
            status_bar_style: "light",
            action_bar_color: "#000B1D",
          });
          this.setState({ toWallClass: "to-wall-done", toWall: false });
          self.props.changeClass(1);
          break;
        case "VKWebAppShowWallPostBoxFailed":
          bridge.send("VKWebAppSetViewSettings", {
            status_bar_style: "light",
            action_bar_color: "#000B1D",
          });
          break;
        case "VKWebAppShowStoryBoxResult":
          self.setState({
            toHistoryClass: "to-history-done",
            toHistory: false,
          });
          self.props.changeClass(2);
          break;
        default:
          break;
      }
    });
  }

  firstCamera = () => {
    this.setState({ player: 1 });
  };
  secondCamera = () => {
    this.setState({ player: 2 });
  };

  toWall = () => {
    bridge.send("VKWebAppSetViewSettings", {
      status_bar_style: "light",
      action_bar_color: "#000B1D",
    });
    if (this.state.toWall) {
      bridge.send("VKWebAppShowWallPostBox", {
        message: "#МКС_онлайн - взгляд из космоса - https://vk.com/onlineiss",
        attachments: "photo-181889580_456239049",
      });
    }
  };

  ads = () => {
    let link = document.querySelector("#ads");
    link.click();
  };

  toNewStory = () => {
    bridge.send("VKWebAppShowStoryBox", {
      'background_type' : 'image',
      'url' : this.state.backend + 'story'
    });
  }

  render() {
    if (this.props.newbie) return <Redirect to="/intro" />;

    const styleHeader = {
      width: this.props.clientWidth + "px",
    };

    let height;
    let clientWidth = this.props.clientWidth;
    if (clientWidth < 500) height = Math.round((clientWidth / 17) * 9);
    else height = 265;

    let buildISS;

    let heightBuildISS = Math.round(((clientWidth - 20) / 100) * 52);
    if (gif.width > 0) {
      buildISS = (
        <div>
          <h2 className="label">Как собиралась МКС</h2>
          <style>
            { ".build-station { background: url(" +
              gif.src +
              ")no-repeat; background-size: 100% 100%; height: " +
              heightBuildISS +
              "px; }" }
          </style>
          <div className="build-station"></div>
        </div>
      );
    }

    let clearBottom = Number(height) / 6.2;
    let cam1, cam2, player;

    switch (this.state.player) {
      case 1:
        player = this.state.first_link;
        cam1 = "button-active";
        cam2 = "button-cam";
        break;
      case 2:
        player = this.state.second_link;
        cam1 = "button-cam";
        cam2 = "button-active";
        break;
      default:
        break;
    }

    let counter = 1, width;
    counter = 1;
    const legends = this.state.legends.map(data => {
      if (counter === this.state.legends.length) width = "140px";
      else {
        width = false;
        counter = counter + 1;
      }
      return (
        <div
          className="legend"
          onClick={ () => this.props.openBiography(data.id, 2) }
          style={{ minWidth: width }}
          key={data.id}
        >
          <div
            className="photo"
            style={{
              background: "url(" + data.photo + ")no-repeat center center",
              backgroundSize: "cover",
            }}
          >
            &nbsp;&nbsp;&nbsp;
          </div>
          <p className="name">{ data.name }</p>
          <p className="name">{ data.last_name }</p>
          <p className="record">{ data.record1 }</p>
          <p className="record">{ data.record2 }</p>
          <p className="record">{ data.record3 }</p>
        </div>
      );
    });

    return (
      <div>
        <a
          id="ads"
          style={{ display: "none" }}
          href="https://vk.com/issonlinepublic?w=wall-181889580_2281"
        >
          #
        </a>
        <div className="header-space">&nbsp;&nbsp;&nbsp;</div>
        <div className="header" style={ styleHeader }>
          <h1 className="label">МКС онлайн</h1>
        </div>
          <div className="buttons">
            <div className={ this.state.toWallClass } onClick={ this.toWall }>
              На стену
            </div>
            <div className={ this.state.toHistoryClass } onClick={ this.toNewStory }>
              В историю
            </div>
          </div>
          <div className="player">
            <iframe
              src={ player }
              autoPlay={1}
              wmode="direct"
              adfree="1"
              scrolling="no"
              frameBorder="0"
              className="stream-frame"
              webkitallowfullscreen="false"
              allowFullScreen="0"
              title="iss"
              height={ height }
              width="100%"
            ></iframe>
          </div>
          <div className="buttons">
            <div className={ cam1 } onClick={this.firstCamera}>
              Камера 1
            </div>
            <div className={ cam2 } onClick={this.secondCamera}>
              Камера 2
            </div>
          </div>
          <h2 className="label">Где сейчас МКС</h2>
          <div
            className="station-on-map"
            style={{ height: Number(height) - clearBottom + "px" }}
          >
            <iframe
              src="/tracker/index.html"
              width="100%"
              height={ height + "px" }
              frameBorder="0"
              title="ISS"
            ></iframe>
          </div>
          { buildISS }
          <h2 className="label" style={{ marginTop: "30px" }}>
            Легенды космоса
          </h2>
          <div className="crew">{ legends }</div>
        { this.state.promoBannerProps &&
        <FixedLayout vertical="bottom">
          <PromoBanner onClose={ () => this.setState({ promoBannerProps: null }) } bannerData={ this.state.promoBannerProps } />
        </FixedLayout> }
      </div>
    );
  }
}

export default Station;
