import React from "react";
import { Route, Switch, Link } from "react-router-dom";
import bridge from "@vkontakte/vk-bridge";
import { View, Panel } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import "./style.css";
import axios from "axios";
import Station from "./components/Station";
import Intro from "./components/Intro";
import Crew from "./components/Crew";
import * as eruda from 'eruda';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backend: "https://api.skorit.ru/iss/",
      fetchedUser: null,
      indexCrew: 1,
      whomSwipe: 1,
      toWallClass: "to-wall-button",
      toHistoryClass: "to-history-button",
      toWall: true,
      toHistory: true,
      first_link: 'https://www.ustream.tv/embed/17074538??html5ui=1&autoplay=true&controls=false&quality=4',
      second_link: 'https://www.ustream.tv/embed/9408562?html5ui=1&autoplay=true&controls=false&quality=4'
    }
  }

  componentDidMount() {
    bridge.subscribe(e => {
      switch (e.detail.type) {
        case "VKWebAppGetUserInfoResult":
          if (e.detail.data.id === 191781124) {
            eruda.init();
            console.log('v1.0.0');
          }
          axios.get(this.state.backend + 'checkUser', {
            params: {
              id: e.detail.data.id,
              timezone: e.detail.data.timezone
            }
          }).then((res) => {
            this.setState({
              newbie: res.data.data.newbie
            });

            if (res.data.data.action === 'group') {
              axios.get(this.state.backend + 'checkGroup', {
                params: { id: e.detail.data.id }
              }).then((res) => {
                if (!res.data.data) {
                  setTimeout(() => {
                    bridge.send("VKWebAppJoinGroup", {
                      group_id: 181889580
                    });
                  }, 15000);
                }
              });
            } else if (res.data.data.action === 'notify') {
              axios.get(this.state.backend + 'checkNotify', {
                params: { id: e.detail.data.id }
              }).then((res) => {
                if (!res.data.data) {
                  setTimeout(() => {
                    bridge.send("VKWebAppAllowNotifications", {});
                  }, 15000);
                }
              });
            }
          });
          break;
        default:
          break;
      }
    });
    bridge.send("VKWebAppGetUserInfo", {});
    bridge.send("VKWebAppSetViewSettings", {
      status_bar_style: "light",
      action_bar_color: "#000B1D",
    });
  }

  openBiography = (value, type) => {
    this.setState({ indexCrew: Number(value) - 1, whomSwipe: type });
    const link = document.querySelector("#crew");
    link.click();
  };

  notNewbie = () => {
    this.setState({ newbie: false });
  };

  changeClass = type => {
    if (type === 1)
      this.setState({ toWallClass: "to-wall-done", toWall: false });
    else if (type === 2)
      this.setState({ toHistoryClass: "to-history-done", toHistory: false });
  };

  render() {
    let clientWidth = document.body.clientWidth;
    if (clientWidth > 500) clientWidth = 500;

    return (
      <View activePanel="app">
        <Panel id="app">
          <div className="max">
            <Link to="/crew" style={{ display: "none" }} id="crew">
              Биография
            </Link>
            <Switch location={this.props.location}>
              <Route
                exact
                path="/"
                render={props => (
                  <Station
                    {...props}
                    crew={this.state.crew}
                    first_link={this.state.first_link}
                    second_link={this.state.second_link}
                    newbie={this.state.newbie}
                    openBiography={this.openBiography}
                    openLegend={this.openLegend}
                    toWallClass={this.state.toWallClass}
                    toHistoryClass={this.state.toHistoryClass}
                    changeClass={this.changeClass}
                    toWall={this.state.toWall}
                    toHistory={this.state.toHistory}
                    clientWidth={clientWidth}
                    backend={this.state.backend}
                  />
                )}
              />
              <Route
                exact
                path="/intro"
                render={props => <Intro {...props} notNewbie={this.notNewbie} />}
              />
              <Route
                exact
                path="/crew"
                render={props => (
                  <Crew
                    {...props}
                    crew={this.state.crew}
                    whomSwipe={this.state.whomSwipe}
                    indexCrew={this.state.indexCrew}
                    clientWidth={clientWidth}
                  />
                )}
              />
            </Switch>
          </div>
        </Panel>
      </View>
    );
  }
}

export default App;
